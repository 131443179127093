
import store from '@/store'
import axios from 'axios'
import { ElMessage } from 'element-plus'

// https://www.javasoho.com/axios/index.html

// 是否在刷新中
var isRefreshing = false
// 重试队列 每一项都是一个待执行待函数
var requestsList = []

// 登录失效之后只提示一次
let loginStatusCount=0

/**
 * 通用请求拦截配置
 * @param {*} config
 */
const serverConf = (config) => {
  var token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  // config.headers['content-type'] = 'application/json'
  return config
}

// 请求成功
var responseFunDataSuccess=async response => {
    // 获取状态码
    const code=response.status
    if(code==200){
      loginStatusCount=0
      return Promise.resolve(response.data)
    }else{
      const mesg = response.error
      if (mesg.indexOf('安全令牌验证失败') !== -1) {
        return LoginFailure()
      } else {
        ElMessage({
          message: mesg || '网络连接失败！',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.resolve({status:false,mesg:mesg})
      }
    }
  }
  // 请求失败信息
  var responseFunDataError=async error => {
    if(error==null || error==undefined){
      return Promise.resolve({status:false,mesg:'未知错误'})
    }
    else if(error instanceof String){
      return Promise.resolve({status:false,mesg:error})
    }
    else if(error.code==='ERR_NETWORK'){
      ElMessage({
        message: '网络不可用，请检查您的网络设置！',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.resolve({status:false,mesg:'网络不可用，请检查您的网络设置'})
    }else{
      const status = error.response.status
      if (status === 401) {
        var accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
          if(checkLoginDuration()){
            return await buildRefreshToken(accessToken, error.response)
          }else{
            return LoginFailure()
          }
        }else{
          return LoginFailure()
        }
      }else if(status==403){
        ElMessage({
          message: '您无权限操作此项功能！',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.resolve({status:false,mesg:'您无权限操作此项功能！'})
      }
      else {
        ElMessage({
          message: '网络不可用，请检查您的网络设置！',
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.resolve(error)
      }
    }
  }
// #region 服务1
const instance = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    baseURL: window.config.Api_URL,
    withCredentials: false, // 允许携带cookie
    timeout: 30000 * 60
  })
  // 配置请求前--拦截器
  instance.interceptors.request.use(serverConf)
// 配置返回数据--拦截器
instance.interceptors.response.use(responseFunDataSuccess,responseFunDataError)
// #endregion
/**
 * 
 * @returns 登录失效
 */
const LoginFailure=(msg)=>{
  loginStatusCount++
  if(loginStatusCount==1){
    ElMessage({
      message: msg ?? '登录已失效，请重新登录！',
      type: 'error',
      duration: 5 * 1000
    })
    store.commit('loginStatus', false)
  }
  return Promise.resolve({status:false,mesg:'登录已失效，请重新登录'})
}
/**
 * 检查是否开启7天免登录
 */
const checkLoginDuration=()=>{
  const retIsLog=localStorage.getItem('isSevenDayLogin')
  if(retIsLog!=null && retIsLog!=undefined && retIsLog!='' ){
    var time=Date.parse(retIsLog.replace(/-/g, "/"))
    var current=new Date()
    // 当前时间减去登录时间
    var Difference_In_Time = current - time
    // 计算相差的天数
	var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
    if(Difference_In_Days>7){
      localStorage.removeItem('isSevenDayLogin')
      return false
    }
    else{
      return true
    }
  }
  return false
}
// 构建刷新
const buildRefreshToken = async(accessToken, response) => {
  if (!isRefreshing) {
    isRefreshing = true
    const tokenEntity = await refreshToken(accessToken)
    isRefreshing = false
    if (tokenEntity.status) {
      let token=tokenEntity.item
      response.config.headers.Authorization = token
      // 已经刷新了token，将所有队列中的请求进行重试
      requestsList.forEach(cb => cb(token))
      requestsList = []
      return instance(serverConf(response.config))
    }else{
      store.commit('loginStatus', false)
      Promise.resolve(tokenEntity)
    }
  } else {
    return new Promise((resolve) => {
      // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
      requestsList.push((token) => {
        response.config.headers.Authorization = token
        resolve(instance(serverConf(response.config)))
      })
    })
  }
}
/**
 * 刷新Token
 * @param {*} accessToken 
 * @returns 
 */
function refreshToken(accessToken) {
  if(accessToken==undefined || accessToken==null){
    accessToken=localStorage.getItem('accessToken')
  }
  return new Promise((resolve, reject) => {
    var serverURL=window.config.Api_URL
    axios.post(serverURL + '/api/System/Authorization/RefreshToken', { accessToken: accessToken }).then(res => {
      if (res && res.data) {
        if (res.data.status && res.data.result.status) {
          var token = res.data.result.token
          var accessToken = res.data.result.accessToken
          localStorage.setItem('token', token)
          localStorage.setItem('accessToken', accessToken)
          resolve({status:true,mesg:'获取Token成功',item:token})
        } else {
          
          // 清除本地存储
          localStorage.removeItem('token')
          localStorage.removeItem('accessToken')
          localStorage.removeItem('isSevenDayLogin')
          resolve({status:false,mesg:res.data.result.mesg})
          ElMessage({
            message: res.data.result.mesg,
            type: 'error',
            duration: 5 * 1000
          })
        }
      }
    }).catch(err => {
      reject(err)
      ElMessage({
        message: err,
        type: 'error',
        duration: 5 * 1000
      })
    })
  })
}
export  { instance, refreshToken}
/**
 * Get方法请求
 * @param {*} url url地址
 * @param {*} param 参数
 * @returns 返回JSON
 */
export const httpGet = (url, param) => { 
  return instance.get(url, { params: param }) 
}
/**
 * Post方法请求
 * @param {*} url url地址
 * @param {*} param 参数
 * @returns 返回JSON
 */
export const httpPost = (url, param) => { return instance.post(url, param) }
/**
 * Put方法请求
 * @param {*} url url地址
 * @param {*} param 参数
 * @returns 返回JSON
 */
export const httpPut = (url, param) => { return instance.put(url, param) }
/**
 * Delete方法请求
 * @param {*} url url地址
 * @param {*} param 参数
 * @returns 返回JSON
 */
export const httpDelete = (url, param) => { return instance.delete(url, { data: param }) }
/**
 * 上传文件
 * @param {*} url 
 * @param {*} formData FormData对象
 * @param {*} progressEvent 返回上传进度值回调
 */
export const httpUpload=(url,formData,progressEvent)=>{
  let config={
    timeout:0,    // 指定请求超时的毫秒数(0 表示无超时时间)
    shouldResetTimeout:true,// 重试的时候是否重置超时时间
    headers:{
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress:progressEvent
  }
  return instance.post(url,formData,config)
}

