
import { getPermission } from '@/http/api/Permission/PermissionManager'
import { ElMessage, ElLoading } from 'element-plus'
import { watch } from 'vue'
import store from '@/store'

let loading
let loadingCount = 0
// 定义公共方法
export default {
    install(app) {
    /**
     * 日期格式化
     * @param {*} datestr 日期
     * @param {*} fmt yyyy-MM-dd
     * @returns 
     */
      app.config.globalProperties.formats=function(datestr, fmt) {
        if (datestr == null) return ''
        if (datestr === undefined) return ''
        if (typeof datestr === 'undefined') return ''
        if (datestr === '') return ''
        fmt = fmt || 'yyyy-MM-dd'
        // 注：不论安卓还是ios，请将时间如 2020-02-02 20:20:20 转化为 2020/02/02 20:20:20 这种形式后再使用，否则无法转换，如下转换即可↓
        datestr = datestr.replace(/-/g, '/') // 这里转化时间格式为以/分隔形式
        var date = new Date(datestr)
        var o = {
          'M+': date.getMonth() + 1, // 月份
          'd+': date.getDate(), // 日
          'h+': date.getHours(), // 小时
          'm+': date.getMinutes(), // 分
          's+': date.getSeconds(), // 秒
          'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
          'S': date.getMilliseconds() // 毫秒
        }
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        for (var k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
        return fmt
      }
      /**
       * 
       * @returns 获取当前时间
       */
      app.config.globalProperties.getCurrDate=function(){
        var dt = new Date()
        var y=dt.getFullYear()
        var m=dt.getMonth()+1
        var d=dt.getDate()
        // 返回的是0-6的数字，0 表⽰星期天
        var w=dt.getDay()
        var retDate=y+'年'+m+'月'+d+'日 星期'+'日一二三四五六'.charAt(w)
        return retDate
      }
      /**
       * 单位转换，超过10000，格式：1万
       * @param {*} num 
       * @returns 
       */
      app.config.globalProperties.toUnit=function(num, unit='万') {
        if (num) {
          if (num >= 10000) {
            return (num / 10000).toFixed(1) + unit
          } else {
            return num
          }
        }
        return '0'
      }
      /**
       * 显示图片尺寸 url为图片路径，
       * @param {*} url 图片地址
       * @param {*} size 显示尺寸，如：50x30，不带"@""
       * @returns 返回完整的地址
       */
      app.config.globalProperties.toImage=function(url, size) {
        if (url == null) return ''
        if (size == null || size === undefined || size === '' || size === 'undefined') {
          return app.config.globalProperties.hostUrl(url,2)
        } else {
          // 先截取路径及文件名称（不带扩展名）
          var pathAndFileName = url.substr(0, url.lastIndexOf('.'))
          // 获取扩展名（带.）
          var newUrl2 = url.substr(url.lastIndexOf('.'))
          var fileName = pathAndFileName + '@' + size + newUrl2
          return app.config.globalProperties.hostUrl(fileName,2)
        }
      }
      /**
       * 本地地址前加域名
       * @param {*} path 地址
       * @param { * } type 1或null为API地址，2=图片服务器地址
       * @returns 返回如果未还有http://等标准前缀，则添加本地process.env地址
       */
      app.config.globalProperties.hostUrl=function(path,type) {
        if (path) {
          if (path.startsWith('http://') ||
              path.startsWith('https://') ||
              path.startsWith('blob:')) {
            return path
          }
          var serverURL=''
          if(type==2){
            serverURL=window.config.Image_URL
          }else{
            serverURL=window.config.Api_URL
          }
          if (path.startsWith('/')) {
            return serverURL + path
          }
          return serverURL + '/' + path
        }
        return ''
      }
      /**
       * 隐藏手机号
       * @param {*} input 手机号
       * @returns 返回隐藏中间四位数的手机号
       */
      app.config.globalProperties.hidePhoto=function(input) {
        if (input === undefined || input === 'undefined') return ''
        if (input) {
          if (Number(input) && String(input).length === 11) {
            var mobile = String(input)
            var reg = /^(\d{3})\d{4}(\d{4})$/
            return mobile.replace(reg, '$1****$2')
          } else {
            return input
          }
        }
        return ''
      }
      /**
       * 判断是否为空或null，
       * @param {*} str 字符串
       * @returns 如果为空则返回：true，否则返回false
       */
      app.config.globalProperties.isNullOrEmpty=function(str){
        if (str == null || str === undefined || str === 'undefined' || str.trim() === '') {
            return true
          }
          return false
      }
      /**
       * 全屏
       */
      app.config.globalProperties.handleFullScreen=function(){
        const element = document.documentElement
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      /**
       * 退出全屏
       */
      app.config.globalProperties.exitFullScreen=function() {
          if (document.exitFullscreen) {
            document.exitFullscreen()
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
          }
      }
      /**
       * 判断当前是否全屏
       * @returns 全屏时返回true，反之
       */
      app.config.globalProperties.isFullScreen=function(){
        return   (
          document.fullscreen || 
          document.mozFullScreen ||                         
          document.webkitIsFullScreen ||       
          document.webkitFullScreen || 
          document.msFullScreen 
       )
      }
      /**
       * 获取界面高度
       * @param {*} height 要赋值的变量，不带value
       * @param {*} maxheight 可设置最大高度
       */
      app.config.globalProperties.clientHeight=function(height,maxheight){
        let  fixHeight=maxheight ?? 120
        let h=0
        if(document.body.clientHeight&&document.documentElement.clientHeight)
        {
             h = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
        }
        else
        {
             h = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
        }
        height.value=h-fixHeight
      }
      /**
       * 获取分页参数大小
       */
      app.config.globalProperties.getPageSizes=function(){
        const pageSizes = [20, 30, 40, 50, 60, 80, 100]
        return  pageSizes
      }
      /**
       * 获取权限
       * @param {*} route 
       * @returns 
       */
      app.config.globalProperties.queryPermission=async(route)=>{
        var action={
          add: false,
          update: false,
          query: false,
          delete: false
        }
        var rName=route.fullPath
        const data = (await getPermission(rName))
        if (data && data.status) {
          action = data.result
        }
        return action
      }
      /**
       * 显示加载
       * @param {*} option 
       */
      app.config.globalProperties.showLoading=(option)=>{
        let options=option || {
          lock: true,
          text: "提交中...",
          background: "rgba(0, 0, 0, 0.5)"
        }
        if (loadingCount === 0) {
          loading = ElLoading.service(options);
        }
      }
      /**
       * 关闭加载
       * @returns 
       */
      app.config.globalProperties.closeLoading=()=>{
        if (loadingCount <= 0) return
        loadingCount--
        if (loadingCount === 0 && loading) {
          loading.close()
        }
      }
      /**
       * 添加回车事件
       * @param {*} fn 执行方法
       */
      app.config.globalProperties.addEnter=(fn)=>{
        window.addEventListener('keydown', fn,true)
      }
      /**
       * 销毁回车事件
       * @param {*} fn 执行方法
       */
      app.config.globalProperties.removeEnter=(fn)=>{
        window.removeEventListener('keydown', fn, true)
      }
      /**
       * 复制
       * @param {msg} msg 需要复制的信息
       */
      app.config.globalProperties.copy=(msg)=>{
        let isSuccessRef = false;
    
        //创建input标签
        var input = document.createElement("input");
        //将input的值设置为需要复制的内容
        input.value = msg;
        //添加input标签
        document.body.appendChild(input);
        //选中input标签
        input.select();
        //执行复制
        document.execCommand("copy");
        if (document.execCommand("copy")) {
          isSuccessRef = true;
        } else {
          isSuccessRef = false;
        }
        //移除input标签
        document.body.removeChild(input);
        if(isSuccessRef){
          ElMessage.success('复制成功！')
        }else{
          ElMessage.error('复制失败！')
        }
      }
      /**
       * 向上查找树型，每一级的父Id
       * @param {*} id 要查找的父Id
       * @param {*} list 树型列表
       * @param {*} result 需要返回的数组对象
       * @returns 
       */
      app.config.globalProperties.findTreeParentIds=(id, list, result)=>{
          for (let i = 0; i < list.length; i += 1) {
            // console.log('*******', list[i].id, '********')
            const item = list[i]
            // 找到目标
            if (item.id === id || item.value===id) {
                // console.log('找到了')
                // 加入到结果中
                result.push(item.id ?? item.value)
                // 因为可能在第一层就找到了结果，直接返回当前结果
                if (result.length === 1) return result
                return true
            }
            // 如果存在下级节点，则继续遍历
            if (item.children) {
                // 预设本次是需要的节点并加入到最终结果result中
                result.push(item.id ?? item.value)
                const find = app.config.globalProperties.findTreeParentIds(id, item.children, result)
                // 如果不是false则表示找到了，直接return，结束递归
                if (find) {
                    return result
                }
                // 到这里，意味着本次并不是需要的节点，则在result中移除
                result.pop()
            }
        }
        // 如果都走到这儿了，也就是本轮遍历children没找到，将此次标记为false
        return false
      }
      /**
       * el-table分页时进行反显已选择的行
       * 实现翻页缓存已选项，和更新填充数据时，设置翻页选中项
       * 1、在el-table中添加:row-key="getRowKey"
       * 2、在el-table中添加@select，而不是@selection-change
       * 3、在el-table-column列类型为：type="selection"中添加 :reserve-selection="true" 在数据更新之后保留之前选中的数据
       * 4、添加变量：selectAllIds，用于接收已选中的项，参考：handleSelectionAll(rows, row)方法，
       * 5、如果是更新，先读取数据，把已选择的Id值赋给selectAllIds变量
       * 6、在分页方法中，添加如下：
       * if (selectAllIds.value.length > 0) {
            nextTick(() => {
              items.value.forEach((item, index) => {
                if (selectAllIds.value.findIndex(x => x === item.id) >= 0) {
                  attrTable.value.toggleRowSelection(attrTable.value.data[index], true)
                }
              })
            })
          }
       * items为接收分页时的数据列表变量
       * @param {*} rows 为@select事件的参数 已选择的行数
       * @param {*} row 为@select事件的参数
       * @param {*} selectAllIds 为存储已选中的行Id变量
       */
      app.config.globalProperties.reverseSelectAllCheckBox=(rows, row,selectAllIds)=>{
        const arr = [].concat(rows)
        const selected = rows.length && rows.indexOf(row) !== -1
        if (selected) {
          arr.map(item => {
            const flag = selectAllIds.value.some(x => {
              return x === item.id
            })
            if (!flag) {
              selectAllIds.value.push(item.id)
            }
          })
        } else {
          selectAllIds.value.map((item, index) => {
            if (item === row.id) {
              selectAllIds.value.splice(index, 1)
            }
          })
        }
      }
      /**
       * 判断IP地址是否合法，仅支持IPV4
       * @param {*} ip 如果合法返回 true , 如果不合法返回 false
       */
      app.config.globalProperties.isIP=(ip)=>{
        var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
        return reg.test(ip);
      }
      /**
       * 生成产品多规格
       * @param {*} items [{ id:xx, name:xx, index:1,letter:xx, items:['白色','黑色','灰色','红色']},{ id:xx, name:xx, index:1,letter:xx, items:['S','M','L','XL']}]
       * @returns 返回已组合完成的规格数组 ['白色', 'M', '1000g', '大白菜']
       */
      app.config.globalProperties.generateSpecItems=(items)=>{
          var specValueList = [];
          for(var i in items){
              var s = items[i];
              var arrValue = s.items;
              specValueList.push(arrValue);
          }
          var arrGroup = [], tempGroup = [];
          if(specValueList.length > 0){
              var firstSpecValueList = specValueList[0];
              for(var ii in firstSpecValueList){
                  tempGroup.push([firstSpecValueList[ii]]);
              }
              specValueList.splice(0, 1);
              arrGroup = app.config.globalProperties.generateSpecGroups(specValueList, tempGroup);
          }
          return arrGroup
      }
      /**
       * 生成产品规格值组合
       * @param {*} arrSpecValueList ['白色','黑色']
       * @param {*} tempGroup ['白色','黑色']
       * @returns 返回已组合完成的规格数组 ['白色', 'M', '1000g', '大白菜']
       */
      app.config.globalProperties.generateSpecGroups=(arrSpecValueList, tempGroup)=>{
          if(arrSpecValueList && arrSpecValueList.length>0){
            for(var i in arrSpecValueList){
                var s = arrSpecValueList[i]
                var newTempGroup = []
                for(var j in tempGroup){
                    for(var k in s){				
                        var tempOne = []
                        for(var x in tempGroup[j]){
                            tempOne.push(tempGroup[j][x])
                        }				
                        tempOne.push(s[k]);	
                        newTempGroup.push(tempOne)
                    }
                }
                if(newTempGroup.length > 0){
                    tempGroup = newTempGroup
                    
                }
            }
        }
        return tempGroup
      }
      /**
       * 获取对象的Key键名
       * @param {*} obj 
       * @returns 返回格式：[键1,键2...]
       */
      app.config.globalProperties.getObjectKeys=(obj)=>{
        if(obj){
          return Object.keys(obj)
        }
        return null
      }
      /**
       * 获取对象的键值
       * @param {*} obj 
       * @returns 返回格式：[值1,值2...]
       */
      app.config.globalProperties.getObjectValues=(obj)=>{
        if(obj){
          return Object.values(obj)
        }
        return null
      }
      /**
       * 根据键名获取对象的键值
       * @param {*} obj 
       * @returns 返回格式：[值1,值2...]
       */
      app.config.globalProperties.getObjectValues=(obj,key)=>{
        if(obj && key){
          var arr=app.config.globalProperties.getObjectKeys(obj)
          if(arr){
            var keyName=arr.find(x=>x==key)
            if(keyName!=undefined && keyName!=null){
              return obj[keyName]
            }
          }
        }
        return null
      }
      /**
       * 监听WebSocket传递过来的命令
       * @param {*} commands 要监听的命令
       * @param {*} callback 回调 {cmd:'',body:''}
       */
      app.config.globalProperties.onListenCommand=(commands,callback)=>{
        if(commands){
          var isArr=true
          if(typeof commands =='string'){
            isArr=false
          }
          let args = Array.from(commands);
          watch(()=>store.state.commandItem,async(val)=>{
            if(val){
              var cmd=val.cmd
              if(isArr){
                var result= args.filter(it=>it==cmd)
                if(result && callback)callback(val)
              }else{
                if(commands==cmd && callback)callback(val)
              }
            }
          },{immediate:true,deep:true})
        }
      }
    }
}

