<template>
    <div class="ui-my-wrap">
      <div class="left">
        <i class="el-icon-lx-info" />
        <el-icon><InfoFilled/></el-icon>
      </div>
      <div class="right">
        <div v-for="(item,index) in items" :key="index">{{ item }}</div>
      </div>
    </div>
</template>
  <script>
  // 信息提示
  import { InfoFilled } from '@element-plus/icons-vue'
  export default {
    components: {InfoFilled},
    name: 'MessageTip',
    props: {
      items: {
        type: Array,
        default: () => ['温馨提示']
      }
    }
  }
  </script>
  <style  scoped>
      .ui-my-wrap{
          margin: 16px 0;
          padding: 10px;
          border: 1px solid #e8d5bb;
          border-radius: 5px;
          background: #fffcf7;
          display: flex;
          display: -webkit-flex;
          flex-flow: row;
      }
      .ui-my-wrap .left{
              width: 30px;
              
          }
      .ui-my-wrap .left i{
                  display: inline-block;
                  width: 20px;
                  color: #e3a04a;
                  font-size: 24px;
              }
      .ui-my-wrap .right{
              flex: 1;
              overflow: hidden;
              font-size: 12px;
              color: #695a46;
          }
  </style>
  