import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueUeditorWrap from 'vue-ueditor-wrap';

/** 导入全局样式 */
import './assets/css/main.css'
import './assets/css/ueditor/dialogbase.css'
import './assets/css/iconfont/iconfont.css'
import '@/assets/css/animate.min.css';

import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
// import 'dayjs/locale/zh-cn'

// 大数据可视化组件--将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import common from  './utils/common'

// 配置websocket
import socketApi from './utils/websocket'
// 打印功能
import print from 'vue3-print-nb'

// 自定义全局组件
import globalComponent from '@/components/GlobalComponents/index'


/**
 * 全局方法挂载
 * App.config.globalProperties.$test = function(msg){alert(msg)}
 * 调用方法：
 * const { proxy } = getCurrentInstance()
 *  proxy.$socket('mittFn')
 */
/**
 * 全局路由守卫
 */

router.beforeEach((to,from,next)=>{
    document.title = ` ${window.config.Title}-${to.meta.title}`
    // 添加到tab中
    if(to.fullPath!=null && to.fullPath!=='/login'){
      let home=to.fullPath=='/dashboard'?true:false
      // 设置是否显示标签栏
      store.commit('setShowTag',!home)
      store.commit('addTab',{title:to.meta.title,path:to.fullPath,home:home})
      store.commit('tabsSort')
    }
    const token = localStorage.getItem('token')
    if (to.path !== from.path) {
      if (!token && to.path !== '/login') {
        next('/login')
      } else {
        // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
        if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
          alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知')
        } else {
          next()
        }
      }
    }
  })
  // 防抖函数 否则会出现错误：ResizeObserver loop limit exceeded
  const debounce = (fn, delay) => {
    let timer = null;
    return function () {
      let context = this;
      let args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        if(fn!=null || fn !== undefined){
          fn.apply(context, args)
        }
        
      }, delay);
    }
  }
  
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
      callback = debounce(callback, 16);
      super(callback);
    }
  }

const app=createApp(App)
// 注册所有Element-Plug图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// 注册全局自定义组件 （对所有需要注册的组件进行遍历并注册）
for (const componentItme in globalComponent) {
  app.component(componentItme, globalComponent[componentItme])
}
app.use(ElementPlus,{
    locale: zhCn,
})
.use(common)
.use(VueUeditorWrap)
.use(dataV)
.use(print)
.use(socketApi)
.use(store)
.use(router)
.mount('#app')


