<template>
  <!-- 插槽通俗的说就是：挖个坑，等组件的使用者进行填充-->
  <div class="gl-card" :class="{'gl-card-border':border}">
    <div class="gl-card-header" v-if="header">
      <div class="gl-card-header-left"><slot name="header"></slot></div>
      <div class="gl-card-header-right"><slot name="action"></slot></div>
    </div>
    <div class="gl-card-body" v-if="body">
      <!-- 定义一个默认插槽 -->
      <slot></slot>
    </div>
    <div class="gl-card-footer" v-if="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>

export default {
  props:{
    // 是否需要边框
    border:{type:Boolean,default:true},
    header:{type:Boolean,default:true},
    body:{type:Boolean,default:true},
    footer:{type:Boolean,default:true}
  }
}
</script>

<style lang="scss" scoped>
.gl-card-border{
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border: 1px solid #e4e7ed;
}
.gl-card{
    flex: 1;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    color: #333333;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    .gl-card-header{
        height: 55px;
        overflow: hidden;
        border-bottom: 1px solid #e4e7ed;
        box-sizing: border-box;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .gl-card-header-left{
          margin-left: 16px;
        }
        .gl-card-header-right{
          margin-right: 16px;
        }
    }
    .gl-card-body{
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 16px;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
    }
    .gl-card-footer{
        overflow: hidden;
        border-top: 1px solid #e4e7ed;
        box-sizing: border-box;
        margin-top: 16px;
        padding-left:240px;
        display: flex;
        align-items: center;
        // justify-content: center;
        
        height: 45px;
        background: #f5f5f5;
    }
}
</style>
