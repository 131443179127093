<template>
  <div class="ui-menu-body" :style="{width:menuOption.width+'px'}">
      <div v-if="loading" class="ui-loading">
        <el-icon class="is-loading" color="#ffffff" size="25">
          <Loading />
        </el-icon>
      </div>
      <div class="ui-menu-body-scroll-wrap">
        <el-scrollbar>
          <el-menu
            v-if="!loading"
            :default-active="currentRoute"
            :collapse="menuOption.collapse"
            background-color="#212c3f"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            collapse-transition
            router
          >
            <template v-for="(item) in data.items">
              <template v-if="item.children.length>0">
                <!-- 第一级  -->
                <el-sub-menu :key="item.id" :index="item.id.toString()">
                  <template #title>
                    <i class="iconfont" :class="item.icon" />
                    <span>{{ item.title }}</span>
                  </template>
      
                  <!-- 第二级 -->
                  <template v-for="subItem in item.children">
                    <template v-if="subItem.children.length>0">
                      <el-sub-menu
                        :key="subItem.id"
                        :index="subItem.id"
                      >
                        <template #title>
                          <i class="iconfont" :class="subItem.icon" />
                          <slot name="title">{{ subItem.title }}</slot>
                        </template>
                        <!-- 第三级  -->
                        <el-menu-item
                          v-for="threeItem in subItem.children"
                          :key="threeItem.id"
                          :index="threeItem.routerName"
                        >
                          <i class="iconfont" :class="threeItem.icon" />
                          <span>{{ threeItem.title }}</span>
                        </el-menu-item>
                        <!-- 第三级  End()-->
                      </el-sub-menu>
                    </template>
                    <!-- 如果没有第三级，就直接显示第二级 -->
                    <el-menu-item
                      v-if="subItem.children.length<=0"
                      :key="subItem.id"
                      :index="subItem.routerName"
                      :popper-offset="2"
                    >
                      <i class="iconfont" :class="subItem.icon" />
                      <span>{{ subItem.title }}</span>
                    </el-menu-item>
                  </template>
                </el-sub-menu>
                <!-- 第一级 End() -->
              </template>
              <template v-else>
                <!-- 只有一级 -->
                <el-menu-item :key="item.id" :index="item.routerName">
                  <i class="iconfont" :class="item.icon" />
                  <template #title><span>{{ item.title }}</span></template>
                </el-menu-item>
                <!-- 只有一级 End-->
              </template>
            </template>
          </el-menu>
    </el-scrollbar>
    </div>
  </div>
</template>
<script>
import { queryByPermissionsMenu } from '@/http/api/Permission/PermissionManager'
import { onBeforeMount, onMounted, ref,reactive, computed, watch } from 'vue'
import bus from '@/utils/bus'
// import { useRoute } from 'vue-router'
import { Loading } from '@element-plus/icons-vue'
import store from '@/store'

export default {
  components: {Loading},
  setup(){
    // vue3需要引用route才能使用，不像vue2通过 this.$route
    // const route = useRoute()
    const currentRoute=ref(null)
    const menuOption = reactive({
        width:200,
        collapse: false
    })
    const loading = ref(false)
    const data=reactive({items: []})


    //返回要监听Vuex中的属--当前路径
    const getPath=computed(()=>store.state.tabs.activePath)
    // 监听当前页面
    watch(()=>getPath,(val)=>{
      if(val){
        currentRoute.value=val.value.replace('/','')
      }
    },{immediate:true,deep:true}) 


    onBeforeMount(()=>{
      // 监听事件总线--菜单展开
      bus.$on('collapse',(msg)=>{
        menuOption.collapse=msg
          if(msg){
            menuOption.width = 65
          }
          else{
            menuOption.width = 200
          }
          bus.$emit('collapse-content', msg)
      })
    })
    // 获取菜单
    const queryLeftMenu=async ()=> {
      loading.value = true
      var queryMenuData = (await queryByPermissionsMenu())
      loading.value = false
      if(queryMenuData && queryMenuData.status){
        data.items = queryMenuData.result
      }
    }
    onMounted(()=>{
        queryLeftMenu()
        // currentRoute.value=route.fullPath.replace('/','')
    })
    return {
        loading,
        data,
        menuOption,
        currentRoute
    }
  }
}
</script>

<style scoped>
.ui-loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ui-menu-body{
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: width 400ms; 
}
.ui-menu-body-scroll-wrap{
  flex: 1;
  overflow: hidden;
}
.ui-menu-body-scroll-wrap .el-scrollbar{
  height: 100%;
}
.ui-menu-body-scroll-wrap .el-scrollbar .el-menu{
  border: none !important;
}
.ui-menu-body-scroll-wrap .el-scrollbar .el-menu-item{
  border: none !important;
}
.ui-menu-body-scroll-wrap .el-scrollbar .el-menu--popup{
  min-width:auto !important;
  border:none !important
}
.ui-menu-body-scroll-wrap .el-scrollbar .el-popper{
  border:none !important
}
.ui-menu-body-scroll-wrap .el-scrollbar .el-popper .is-light{
  border:none !important
}
.ui-menu-body-scroll-wrap .el-scrollbar .el-menu-item:hover{
  background-color: #1c1f24 !important;
  color:#20a0ff !important;
  transition: all 500ms !important;
}
.iconfont{margin-right: 8px;}
</style>