/**
 * WebSocket封装方法
 * 在main.js中引入这个文件
 * import * as socketApi from './api/socket'
 * App.config.globalProperties.$socket = socketApi
 * 使用方法：
 * const { proxy } = getCurrentInstance()
 *  proxy.connection('url')
 */
import { ElMessage } from "element-plus"
import { refreshToken } from '@/http/request'
// websocket对象
let ws
// 是否已连接
let isconnection = false
// 心跳包时间(6秒)
let heartbeatTime=3*1000
// 心跳包定时器Id
let heartbeatTimeOutId=null

// 重连时间（3秒）
let reconnectionTime=3*1000
// 断线重连定时器Id
let reconnectTimeoutId=null


export default {
  install(app) {
    // /连接websocket
    app.config.globalProperties.connection = function (url, callback) {
      try {
          if(ws!=null){
            app.config.globalProperties.close()
            ws=null
          }
          ws = new WebSocket(url)
          // 先置为连接中
          isconnection = true
          // 清除定时器
          app.config.globalProperties.resetTime()

          ws.onopen = function () {
            // 开始心跳包
            app.config.globalProperties.heartbeat()
            // 回调
            if(callback!=undefined){
            callback(isconnection)
            }
          }
          ws.onclose = function () {
            isconnection = false
            app.config.globalProperties.reconnection(url)
          }
          ws.onerror = function () {
            isconnection = false
            app.config.globalProperties.reconnection(url)
            // 回调
            if(callback!=undefined){
              callback(isconnection)
            }
          }
      }catch(ex){
        console.log(ex)
      }
    }
    // 重置时间
    app.config.globalProperties.resetTime=()=>{
      clearTimeout(reconnectTimeoutId)
      clearInterval(heartbeatTimeOutId)
    }
    // 重连
    app.config.globalProperties.reconnection = (url) => {
        if(isconnection)return
        reconnectTimeoutId=setTimeout(function () {
          app.config.globalProperties.connection(url)
        }, reconnectionTime)
    }
    // 心跳包
    app.config.globalProperties.heartbeat = () => {
      if (typeof ws !== "undefined") {
        if (isconnection && ws) {
          heartbeatTimeOutId=setInterval(function () {
              if(ws && ws.readyState === 1){
                ws.send("{'cmd':'Heartbeat'}")
              }
            }, heartbeatTime)
        }
      }
    }
    // 关闭连接
    app.config.globalProperties.close = () => {
      if (typeof ws !== "undefined") {
        if (isconnection && ws && ws.readyState === 1) {
          ws.send("{'cmd':'Close'}")
          ws.close()
        }
      }
    }
    // 接收信息
    app.config.globalProperties.receiveMessage = (callback) => {
      if (typeof ws !== "undefined") {
        ws.onmessage = function (e) {
          if (e.data) {
            if (callback) {
              var json = JSON.parse(e.data)
              callback(json)
              /*
              if (json.cmd !== "Heartbeat") {
                callback(json)
              }*/
            }
          }
        }
      }
    }
    // 发送信息
    app.config.globalProperties.sendMessage = (content) => {
      if (typeof ws === "undefined") {
        ElMessage.error("websocket未实例化，无法发送信息！")
      } else {
        const json = JSON.stringify(content)
        // console.log(JSON.stringify(ws))
        if (ws.readyState === 1) {
          try{
            ws.send(json)
          }catch{ /* empty */ }
        } else {
          ElMessage.error("WebSocket连接已关闭，无法发送数据!")
        }
      }
    }
    // 刷新Token
    app.config.globalProperties.refreshToken = async() => {
      await refreshToken(null)
    }
  }
}
