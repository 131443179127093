<template>
  <div class="act-box" :class="{'act-box-border':border}" :style="{width:width??'100%'}">
    <slot></slot>
    <slot name="action"></slot>
  </div>
</template>

<script>
export default {
  props:{
    width:String,
    // 是否显示边框
    border:{
        type:Boolean,
        default:true
    }
  }
}
</script>

<style lang="scss" scoped>
.act-box-border{
    border-bottom: 1px solid #e4e7ed;
    margin-bottom: 10px;
}
.act-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    height: 40px;
}
</style>>