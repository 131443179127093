<template>
  <div class="ui-layout-framework-wrap">
    <!--头部-->
    <div class="ui-layout-framework-header ui-box-background">
        <header-bar />
    </div>
    <!--框架内容显示主体-->
    <div class="ui-layout-framework-content">
        <!--左边菜单区域-->
        <div class="ui-layout-framework-content-left ui-box-background">
            <left-menu />
        </div>
        <!--右边-->
        <div class="ui-layout-framework-content-right">
          <!--标签-->
          <div class="ui-layout-framework-content-right-tags-wrap" v-if="showTag">
            <tags-top />
          </div>
          <!--页面展示区域-->
          <div class="ui-layout-framework-content-right-page">
            <!-- <router-view v-slot="{ Component }">
              <transition name="router_animate" mode="out-in">
                <keep-alive>
                  <component :is="Component" />
                </keep-alive>
              </transition>
            </router-view> -->
             <router-view v-slot="{ Component }">
                  <transition name="router_animate" mode="out-in">
                    <keep-alive v-if="$route.meta.keepAlive" :include="routeList" :max="30">
                      <component :is="Component" :key="$route.name" />
                    </keep-alive>
                    <component :is="Component" :key="$route.name" v-else />
                  </transition>
              </router-view>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import { ref,computed, watch, getCurrentInstance, nextTick, onMounted, onUnmounted } from 'vue'
import HeaderBar from './components/HeaderBar.vue'
import TagsTop from './components/TagsTop.vue'
import LeftMenu from './components/LeftMenu.vue'
import store from '@/store'
import { useRouter } from 'vue-router'
export default {
 components:{
  HeaderBar,
  TagsTop,
  LeftMenu
},
 setup(){
   const router=useRouter()
    const { proxy }=getCurrentInstance()
    // 是否显示Tag标签
    const showTag=ref(true)
    // 路由
    const routeList=ref([])

    // 连接服务器
    const connection=()=>{
      if(localStorage.getItem('token')!=null && localStorage.getItem('token')!=undefined){
         let url=window.config.WebSocket_URL+'?token='+localStorage.getItem('token')
        proxy.connection(url,loginWs)
      }
    }
    // 登录服务器
    const loginWs= (isconnect)=>{
        if(isconnect){
          // 接收消息
          proxy.receiveMessage(async res=>{
            // 令牌已过期
            if(res.cmd=='Reconnection'){
              proxy.close()
              await proxy.refreshToken()
              connection()
            }else{
              // 获取到命令，传入到Vuex中
              store.commit('addCommand',res)
            }
          })
        }
    }
    //返回要监听Vuex中的属--路由列表
    const getTabs = computed(()=>{
          return store.state.tabs.items
    })
    
    // 监听
    watch(
    ()=> getTabs,
    (newVal) =>{
      routeList.value=newVal.value.map(x=>x.path.replace("/",""))
    },{immediate:true,deep:true})

    // 监听是否显示标签栏
    watch(()=>store.state.showTag,(val)=>{
        showTag.value=val
    },{immediate:true,deep:true})
    // 监听登录状态，如果为false则退出登录
    watch(()=>store.state.loginStatus,(val)=>{
      if(val==false){
        proxy.close()
        // 清除本地存储
        localStorage.removeItem('token')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('isSevenDayLogin')
        localStorage.clear()
        
        router.push('/login')
      }
    },{immediate:true,deep:true})
    onMounted(async()=>{
      await nextTick()
      connection()
    })
    onUnmounted(() => {
      proxy.close()
    })
    return {
        showTag,
        routeList
    }
 }
}
</script>

<style scoped>
/**背景颜色 */
.ui-box-background{
  background: #212c3f;
}
/**框架主体 */
.ui-layout-framework-wrap{
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
/**框架头部 */
.ui-layout-framework-header{
    margin:0px !important;
    color: #c6d5f1;
    height: 52px !important;
    overflow: hidden;
}
/**框架内容部分 */
.ui-layout-framework-content{
   flex: 1;
   display: flex;
   flex-direction: row;
   overflow: hidden;
}
/**框架内容--左边 */
.ui-layout-framework-content-left{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
/**框架内容--右边 */
.ui-layout-framework-content-right{
  height: 100%;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
/**框架内容--右边--标签 */
.ui-layout-framework-content-right-tags-wrap{
  margin: 0px !important;
  padding: 0px;
  height: 35px;
  overflow: hidden;
}
/**框架内容--右边--页面展示区域 */
.ui-layout-framework-content-right-page{
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #eff0f3;
  overflow: hidden;
}

</style>
