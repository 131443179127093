<template>
    <div v-if="show" class="tags-wrap">
      <ul class="tags-ul">
        <li v-for="(item,index) in tagsList" :key="index" class="tags-li-item" :class="{'active': active==index}">
          <router-link :to="item.path" class="tags-li-title">
            {{ item.title }}
          </router-link>
          <span v-if="item.home" class="tags-li-icon">
            <el-icon><HomeFilled /></el-icon>
          </span>
          <span v-else class="tags-li-icon" @click="closeTags(item)"><el-icon><Close /></el-icon></span>
        </li>
      </ul>
      <div class="tags-close-wrap" v-if="tagsList && tagsList.length>1">
        <el-dropdown @command="closeAll" size="mini">
          <div class="tags-close-btn">
            <span>标签选项</span><el-icon><CaretBottom /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu  size="mini">
              <el-dropdown-item command="all" size="mini">关闭所有</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
</template>
  
  <script>
  // 标签
  import bus from '@/utils/bus'
  import { ref, onBeforeMount, watch, onMounted, nextTick } from 'vue'
  import {  useRouter } from 'vue-router'
import store from '@/store'

  // tab标签
  export default {
    setup() {
      // 标签列表
      const tagsList= ref([])
      const path = ref(null)
      const show = ref(true)
      const router=useRouter()
      const active=ref(0)

      // 监听
      watch(
        ()=> store.state.tabs.items,
        (newVal) =>{
          if(newVal){
              tagsList.value=newVal
          }
        },{immediate:true,deep:true})
      watch(()=>store.state.tabs.active,(val)=>{
        active.value=val
      },{immediate:true,deep:true})

      // 关闭单个标签
      const closeTags=(item) => {
        store.commit('removeTab',item)
        skipRouter()
      }
      // 关闭所有标签
      const closeAll=()=> {
        store.commit('removeTabAll')
        skipRouter()
      }
      // 跳转
      const skipRouter=()=>{
        const path=store.getters.getTabs.activePath
        router.push(path)
      }
      onMounted(async()=>{
        await nextTick()
        active.value=store.getters.getTabs.active
      })
      onBeforeMount(()=>{
        bus.$on('showTags', (e) => {
            show.value = e
        })
      })
      return {
        closeTags,
        closeAll,
        active,
        path,
        tagsList,
        show
      }
    }
  }
  
  </script>
  
  <style lang="scss" scoped>
      .tags-wrap {
          background: #fff;
          padding-right: 0px;
          box-shadow: 0 3px 8px #ddd;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
      }
  
      .tags-ul {
          box-sizing: border-box;
          flex: 1;
          display: flex;
          height: 30px;
          align-items: center;
          overflow-y: hidden;
          overflow-x: auto;
          margin-right: 16px;
          .tags-li-item{
              position: relative;
              margin: 3px 0px 2px 3px;
              border-radius: 3px;
              font-size: 12px;
              overflow: hidden;
              cursor: pointer;
              height: 23px;
              line-height: 23px;
              border: 1px solid #e9eaec;
              background: #fff;
              padding: 0 20px 0 12px;
              vertical-align: middle;
              color: #666;
              -webkit-transition: all .3s ease-in;
              -moz-transition: all .3s ease-in;
              transition: all .3s ease-in;
              &.active {
                  color: #fff !important;
                  background-color: #409EFF !important;
                  position: relative;
              }
              .tags-li-title {
                    max-width: 80px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-right: 5px;
                    color: #666;
                }
                &.active .tags-li-title {
                  color: #fff !important;
                }
                .tags-li-icon{
                  position: absolute;
                  top:1px;
                  &:hover{
                    animation:iconAnim 0.3s ease-in-out forwards;
                  }
                }
          }
        }
      .tags-close-wrap {
          cursor: pointer;
          background: #fff;
          box-shadow: -3px 0 15px 3px rgba(0, 0, 0, .1);
          overflow: hidden;
          background: #409EFF;
          padding: 0 8px;
          color: white;
          transition: 0.3s all;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover{
            background: #2687e9;
          }
          .tags-close-btn{
            display: flex;
            color: white;
            font-size: 12px;
            align-items: center;
          }
      }
      
    @keyframes iconAnim{
      0%{
        transform: rotate(0deg) scale(1);
        transform-origin: 50% 50%;
      }
      25%{
        transform: rotate(72deg) scale(0.6);
        transform-origin: 50% 50%;
      }
      50%{
        transform: rotate(144deg) scale(1.1);
        transform-origin: 50% 50%;
      }
      75%{
        transform: rotate(216deg) scale(0.8);
        transform-origin: 50% 50%;
      }
      100%{
        transform: rotate(360deg) scale(1.5);
        transform-origin:50% 50%;
      }
    }
  </style>
  