<template>
  <div class="user-info-wrap">
           <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ entity.name }}
            <el-icon><CaretBottom /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item command="updatepwd">修改密码</el-dropdown-item>
                <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-tooltip class="item" effect="dark" placement="top-start">
        <template #content>
            <div class="ui-row">
            <div>用户名称</div><div>{{ entity.name }}</div>
            </div>
            <div class="ui-row">
            <div>用户类型</div><div>{{ entity.admin_TypeName }}</div>
            </div>
            <div class="ui-row">
            <div>用户状态</div><div>
                <el-tag size="mini" type="success" v-if="entity.admin_Status==1">{{ entity.admin_StatusName }}</el-tag>
                <el-tag size="mini" type="warning" v-else-if="entity.admin_Status==2">{{ entity.admin_StatusName }}</el-tag>
                <el-tag size="mini" type="danger" v-else>{{ entity.admin_StatusName }}</el-tag>
            </div>
            </div>
            <div class="ui-row">
            <div>登录次数</div><div><span>{{ entity.admin_LoginCount }}</span>次</div>
            </div>
            <div class="ui-row">
            <div>最后登录</div><div>{{ formats(entity.admin_LastTime,'yyyy-MM-dd hh') }}</div>
            </div>
            <div class="ui-row">
            <div>创建日期</div><div>{{ formats(entity.createTime,'yyyy-MM-dd hh') }}</div>
            </div>
        </template>
        <img src="../../assets/img/headportrait.png">
        </el-tooltip>
    </div>
       
        <!--修改密码弹窗-->
    <el-dialog
      width="450px"
      v-model="updatepwddialog"
      title="修改密码"
      @closed="onClose"
      destroy-on-close
    >
      <el-form ref="form1" label-position="left" :model="formUpdatePwd" label-width="80px">
        <el-form-item
          prop="password"
          :rules="[{ required: true, message: '请输入新密码！' }]"
          label="新密码"
        >
          <el-input v-model="formUpdatePwd.password" placeholder="请输入新密码！" show-password />
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="password2"
          :rules="[{ required: true, message: '请输入确认密码！', validator: validatePass2 }]"
        >
          <el-input v-model="formUpdatePwd.password2" placeholder="请输入确认密码！" show-password />
        </el-form-item>
        <el-row type="flex" justify="center">
          <el-button-group>
            <el-button type="primary" :loading="isupdatepwd" @click="submitUpdatePwdUserForm">立即修改</el-button>
            <el-button type="info" plain @click="updatepwddialog=false;isupdatepwd=false;authorizationStatus=false">取消</el-button>
          </el-button-group>
        </el-row>
        <msg-tip :items="['1、请输入新密码，新密码长度为6-20个字符！','2、请再次输入确认密码，须与新密码一至！']" />
      </el-form>
    </el-dialog>
</template>

<script>
import { CaretBottom } from '@element-plus/icons-vue'
import { updatePassword, queryByBaseInfo } from '@/http/api/AdminRoles/Admin'
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import MsgTip from '@/components/common/MsgTip.vue'
import store from '@/store'
export default {
    components:{
        CaretBottom,
        MsgTip
    },
    setup(){
        const { proxy } = getCurrentInstance()
        const updatepwddialog=ref(false)
        // 定义form表单的ref
        const form1 =ref(null)
        const isupdatepwd = ref(false)
        // 用户消息
        const entity=ref({
            name: '',
            admin_LastTime: '',
            admin_LoginCount: 0,
            admin_TypeName: '',
            admin_Status: '',
            admin_StatusName: '',
            createTime: ''
        })
        // 修改密码
        const formUpdatePwd=reactive({
            password: null,
            password2: null
        })
        const onClose=()=>{
            updatepwddialog.value=false
            formUpdatePwd.password =null
            formUpdatePwd.password2=null
        }
        // 验证两次输入的密码
        const validatePass2= (rule, value, callback) => {
            if (value === '') {
            callback(new Error('请再次输入密码！'))
            } else if (value !== formUpdatePwd.password) {
            callback(new Error('两次输入密码不一致!'))
            } else {
            callback()
            }
        }
        // 获取登录管理员基本信息
        const queryUserByInfo=async()=> {
            const ret = (await queryByBaseInfo())
            if(ret && ret.status){
                entity.value = ret.result
            }
        }
        // 用户名下拉菜单选择事件
        const handleCommand=(command)=> {
            if (command === 'loginout') {
                store.commit('loginStatus', false)
            } else if (command === 'updatepwd') {
                // 修改密码
                updatepwddialog.value = true
            }
        }
        // 修改密码
        var submitUpdatePwdUserForm=async()=> {
            form1.value.validate(async(valid) => {
                if (valid) {
                isupdatepwd.value = true
                var ret = (await updatePassword(formUpdatePwd)).result
                isupdatepwd.value = false
                if (ret && ret.status) {
                    // localStorage.setItem('token',ret.token)
                    proxy.$message.success('密码修改成功，请牢记新密码！')
                    formUpdatePwd.password=null
                    formUpdatePwd.password2=null
                    updatepwddialog.value = false
                } else {
                    proxy.$message.error(ret.mesg)
                }
                } else {
                return false
                }
            })
        }
        onMounted(()=>{
            queryUserByInfo()
        })
        return {
            form1,
            updatepwddialog,
            onClose,
            entity,
            formUpdatePwd,
            validatePass2,
            handleCommand,
            submitUpdatePwdUserForm,
            isupdatepwd
        }
    }
}
</script>

<style scoped>
.user-info-wrap {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.user-info-wrap img {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.el-dropdown-link {
    color: #c6d5f1;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
.user-name{
    margin-right:8px;
}
.ui-row div:last-child{margin-left: 8px;color: #98a9c9;}
</style>