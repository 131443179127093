<template>
  <!--显示面包屑菜单-->
  <div class="ui-breadcrumb-wrap">
    <el-breadcrumb separator="/">
      <template v-for="(im,index) in routeItems">
        <el-breadcrumb-item v-if="index+1!=routeItems.length" :to="im.path" :key="index"><a>{{ im.meta.title }}</a></el-breadcrumb-item>
        <el-breadcrumb-item v-else :key="index+1">{{ im.meta.title }}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </div>
</template>

<script>
import { ref, onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router'
export default {
 setup(){
    const route = useRoute()
    // 获取路由
    const routeItems = ref([])
    // 获取路由数据
    const queryRouteData =()=>{
      const routeData = route.matched
      routeItems.value=[]
    //   routeItems.value.push({ path: '/dashboard', name: 'dashboard', meta: { title: '首页' }})
      if (routeData) {
        routeData.forEach(e => {
          routeItems.value.push(
            {
              path: e.path === '' ? '/' : e.path,
              name: e.name,
              meta: { title: e.meta.title }
            }
          )
        })
      }
    }
    onBeforeMount(()=>{
        queryRouteData()
    })
     // 监听路由变化
     watch(()=>route.matched,()=>queryRouteData())
    return {
        routeItems
    }
 }
}
</script>

<style>
.ui-breadcrumb-wrap{
    margin:0px;
}
.ui-breadcrumb-wrap .el-breadcrumb__inner {
  color: #8f9db9;
  font-weight: normal;
  transition:all 0.3ms !important;
}
.ui-breadcrumb-wrap .el-breadcrumb__inner .is-link{
  color: #f7faff;
  font-weight: normal;
  transition:all 0.3ms !important;
}

.el-breadcrumb__inner{
  color: #bbc3d1 !important;
}
.el-breadcrumb__inner a{
    color: #bbc3d1 !important;
}
.el-breadcrumb__inner a:hover{
    color: #409eff !important;
}
</style>