<template>
  <div class="ui-message">
            <el-tooltip
                effect="dark"
                :content="message?`有${message}条未读消息`:`消息中心`"
                placement="bottom"
            >
                <router-link to="/message">
                    <el-icon :size="24"><Bell /></el-icon>
                </router-link>
            </el-tooltip>
            <span class="ui-message-badge" v-if="message"></span>
        </div>
</template>

<script>
// 消息提示
import { ref, watch } from 'vue'
import { Bell } from '@element-plus/icons-vue'
import store from '@/store'
export default {
    components: {
        Bell
    },
 setup(){
    const message = ref(0)
    // 监听命令
    // watch(()=>store.state.commandItem,async(val)=>{
    //     console.log(val)
    //     message.value++
    // })
    watch(()=>store.state.commandItem,(val)=>{
        if(val.cmd=='Heartbeat'){
            message.value++
        }
    },{immediate:true,deep:true})
    return {
        message
    }
 }
}
</script>

<style scoped>
.ui-message{
    display: flex;
    align-items: center;
    position: relative;
}
.ui-message-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #c6d5f1;
}
.ui-message .el-icon-bell {
    color: #c6d5f1;
}
</style>