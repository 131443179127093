import { createRouter, createWebHistory } from 'vue-router'
/**
 * 路由嵌套：path都加上"/"，否则不显示
 */
/**
 * 首页框架
 */
import home from '@/Layout/HomeLayout.vue'
/**
 * 通用层
 */
import commonLayout from '@/Layout/SubLayout.vue'
const routes = [
  {
    path: '/',
    component:()=> Promise.resolve(home),
    redirect: { name: 'dashboard' },
    meta: { 
      title: '首页',
      keepAlive: true //此页面需要缓存  
    },
    children: [
      // #region 大数据中心
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => Promise.resolve(import('@/views/App/Dashboard/DashboardIndex.vue')),
        meta: { 
          title: '大数据中心',
          keepAlive: true //此页面需要缓存 
        }
      }
      // #endregion
      // #region 用户权限管理
       ,{
        path: '/RoleManagement',
        name: 'RoleManagement',
        redirect: { name: 'adminManagement' }, // 跳转到下一级第一个
        component:()=> Promise.resolve(commonLayout),
        meta: { 
          title: '用户角色',
          keepAlive: true //此页面需要缓存 
        },
        children: [
          {
            path: '/RoleManagement',
            name: 'RoleManagement',
            component: () => Promise.resolve(import('@/views/App/System/AdminRoles/Roles/RoleIndex.vue')),
            meta: { title: '角色管理',keepAlive: true  }
          },
          {
            path: '/RoleCreate',
            name: 'RoleCreate',
            component: () => Promise.resolve(import('@/views/App/System/AdminRoles/Roles/RoleCreate.vue')),
            meta: { title: '操作角色',keepAlive: true}
          },
          {
            path: '/adminManagement',
            name: 'adminManagement',
            component: () => Promise.resolve(import('@/views/App/System/AdminRoles/Admin/AdminIndex.vue')),
            meta: { 
              title: '管理员',
              keepAlive: true //此页面需要缓存 
            }
          },
          {
            path: '/AdminBatchImport',
            name: 'AdminBatchImport',
            component: () => Promise.resolve(import('@/views/App/System/AdminRoles/Admin/AdminBatchImport.vue')),
            meta: { 
              title: '批量导入',
              keepAlive: true //此页面需要缓存 
            }
          }
        ]

      }
      // #endregion
      // #region 消息中心
      ,{
        path: '/message',
        name: 'message',
        meta: { 
          title: '消息中心',
          keepAlive: true //此页面需要缓存 
        },
        component:()=>Promise.resolve( import('@/views/App/Message/MessageIndex.vue'))
      }
      // #endregion
      // #region 系统维护
      ,{
        path: '/SystemMaintLayer',
        name: 'SystemMaintLayer',
        redirect: { name: 'AuditManage' }, // 跳转到下一级第一个
        component: ()=>Promise.resolve(commonLayout),
        meta: { title: '系统维护',keepAlive: true },
        children: [
          {
            path: '/SystemLayou',
            name: 'SystemLayou',
            redirect: { name: 'AuditManage' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '审计管理',keepAlive: true },
            children:[
              {
                path: '/AuditManage',
                name: 'AuditManage',
                component: () => Promise.resolve(import('@/views/App/SystemMaint/Audit/AuditIndex.vue')),
                meta: { title: '安全审计',keepAlive: true }
              }
            ]
          },
          {
            path: '/DataBaseLayou',
            name: 'DataBaseLayou',
            redirect: { name: 'AuditManage' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '数据库管理',keepAlive: true },
            children:[
              {
                path: '/DatabaseConfigIndex',
                name: 'DatabaseConfigIndex',
                component: () => Promise.resolve(import('@/views/App/SystemMaint/Database/DatabaseConfigIndex.vue')),
                meta: { title: '备份配置',keepAlive: true }
              },
              {
                path: '/DatabaseIndex',
                name: 'DatabaseIndex',
                component: () => Promise.resolve(import('@/views/App/SystemMaint/Database/DatabaseIndex.vue')),
                meta: { title: '备份管理',keepAlive: true }
              },
              {
                path: '/DatabaseRecyclebinIndex',
                name: 'DatabaseRecyclebinIndex',
                component: () => Promise.resolve(import('@/views/App/SystemMaint/Database/DatabaseRecyclebinIndex.vue')),
                meta: { title: '回收站',keepAlive: true }
              },
              {
                path: '/DatabaseUploadBackup',
                name: 'DatabaseUploadBackup',
                component: () => Promise.resolve(import('@/views/App/SystemMaint/Database/DatabaseUploadBackup.vue')),
                meta: { title: '上传还原',keepAlive: true }
              }
            ]
          },
          {
            path: '/SystemCacheLayou',
            name: 'SystemCacheLayou',
            redirect: { name: 'CleanCacheIndex' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '系统缓存',keepAlive: true },
            children:[
              {
                path: '/CleanCacheIndex',
                name: 'CleanCacheIndex',
                component: () => Promise.resolve(import('@/views/App/SystemMaint/Cache/ClearCacheIndex.vue')),
                meta: { title: '清理缓存',keepAlive: true }
              }
            ]
          }
        ]
      }
      // #endregion
      // #region 系统配置
      ,{
        path:'/SystemConfig',
        name:'SystemConfig',
        redirect: {name:''},
        component: ()=>Promise.resolve(commonLayout),
        meta: {title:'系统配置', keepAlive: true},
        children: [
          {
            path: '/MallBaseConfigManage',
            name: 'MallBaseConfigManage',
            redirect: { name: 'WeChatConfigIndex' }, // 跳转到下一级第一个
            component:()=>Promise.resolve( commonLayout),
            meta: { title: '支付配置',keepAlive: true },
            children: [
              {
                path: '/WeChatConfigIndex',
                name: 'WeChatConfigIndex',
                meta: { title: '微信支付',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/PaymentSystem/Config/WeChat/WeChatConfigIndex.vue'))
              },
              {
                path: '/WeChatConfigCreate',
                name: 'WeChatConfigCreate',
                meta: { title: '微信支付编辑',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/PaymentSystem/Config/WeChat/WeChatConfigCreate.vue'))
              },
              {
                path: '/AlipayConfigIndex',
                name: 'AlipayConfigIndex',
                meta: { title: '支付宝',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/PaymentSystem/Config/Alipay/AlipayConfigIndex.vue'))
              },
              {
                path: '/AlipayConfigCreate',
                name: 'AlipayConfigCreate',
                meta: { title: '支付宝编辑',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/PaymentSystem/Config/Alipay/AlipayConfigCreate.vue'))
              }
            ]
          },
          {
            path: '/SiteSystemLayou',
            name: 'SiteSystemLayou',
            redirect: { name: 'AdvManagement' }, // 跳转到下一级第一个
            component:()=> Promise.resolve(commonLayout),
            meta: { title: '站点配置',keepAlive: true },
            children: [
              {
                  path: '/SiteManagement',
                  name: 'SiteManagement',
                  component:()=> Promise.resolve(import('@/views/App/Site/SiteConfig/SiteIndex.vue')),
                  meta: { title: '站点配置',keepAlive: true }
              },
               {
                  path: '/AdvManagement',
                  name: 'AdvManagement',
                  component:()=> Promise.resolve(import('@/views/App/Site/Adv/AdvIndex.vue')),
                  meta: { title: '广告列表',keepAlive: true }
               },
               {
                path: '/AdvCreate',
                name: 'AdvCreate',
                component:()=> Promise.resolve(import('@/views/App/Site/Adv/AdvCreate.vue')),
                meta: { title: '创建广告',keepAlive: true }
               },
               {
                path: '/IndexConfig',
                name: 'IndexConfig',
                component: () => Promise.resolve(import('@/views/App/Site/Index/IndexConfig.vue')),
                meta: { title: '首页配置' }
              }
            ]
          },
          {
            path: '/CityLayou',
            name: 'CityLayou',
            redirect: { name: 'CityManagement' }, // 跳转到下一级第一个
            component:()=> Promise.resolve(commonLayout),
            meta: { title: '省市管理',keepAlive: true },
            children: [
              {
                  path: '/CityIndex',
                  name: 'CityIndex',
                  component:()=> Promise.resolve(import('@/views/App/System/City/List/CityIndex.vue')),
                  meta: { title: '省市列表',keepAlive: true }
              },
               /*{
                  path: '/CityRetrieveIndex',
                  name: 'CityRetrieveIndex',
                  component:()=> Promise.resolve(import('@/views/App/System/City/Retrieve/CityRetrieveIndex.vue')),
                  meta: { title: '回收站',keepAlive: true }
               },
               {
                path: '/CitySortIndex',
                name: 'CitySortIndex',
                component:()=> Promise.resolve(import('@/views/App/System/City/Sort/CitySortIndex.vue')),
                meta: { title: '排序',keepAlive: true }
               }*/
            ]
          }
        ]
      }
      // #region 站点管理start
      ,{
        path: '/SiteManagementLayou',
        name: 'SiteLayou',
        redirect: { name: 'AdvManagement' }, // 跳转到下一级第一个
        component: ()=>Promise.resolve(commonLayout),
        meta: { title: '站点管理',keepAlive: true },
        children: [
          {
            path: '/MenuLayou',
            name: 'MenuLayou',
            redirect: { name: 'MenuManage' }, // 跳转到下一级第一个
            component:()=> Promise.resolve(commonLayout),
            meta: { title: '栏目管理' },
            children: [
              {
                path: '/MenuManage',
                name: 'MenuManage',
                component: () => Promise.resolve(import('@/views/App/Site/Menus/Menu/MenuIndex.vue')),
                meta: { title: '栏目列表' }
              },
              {
                path: '/MenuCreate',
                name: 'MenuCreate',
                component: () => Promise.resolve(import('@/views/App/Site/Menus/Menu/MenuCreate.vue')),
                meta: { title: '编辑栏目' }
              },
              {
                path: '/MenuOrderByManage',
                name: 'MenuOrderByManage',
                component: () => Promise.resolve(import('@/views/App/Site/Menus/OrderBy/OrderIndex.vue')),
                meta: { title: '栏目排序' }
              },
              {
                path: '/MenuOrderByChild',
                name: 'MenuOrderByChild',
                component: () => Promise.resolve(import('@/views/App/Site/Menus/OrderBy/OrderChildren.vue')),
                meta: { title: '子栏目排序' }
              }
            ]
          },
          {
            path: '/SiteOnLineLayou',
            name: 'SiteOnLineLayou',
            redirect: { name: 'CustomerDemandIndex' }, // 跳转到下一级第一个
            component:()=> Promise.resolve(commonLayout),
            meta: { title: '留言管理' },
            children: [
              {
                path: '/CustomerDemandIndex',
                name: 'CustomerDemandIndex',
                component: () => Promise.resolve(import('@/views/App/Site/Message/CustomerDemand/CustomerDemandIndex.vue')),
                meta: { title: '客户需求' }
              }
            ]
          },
          {
            path: '/RecruitLayou',
            name: 'RecruitLayou',
            redirect: { name: 'RecruitManage' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '招聘管理' },
            children: [
              {
                path: '/RecruitManage',
                name: 'RecruitManage',
                component: () => Promise.resolve(import('@/views/App/Site/Recruit/RecruitIndex.vue')),
                meta: { title: '招聘列表' }
              },
              {
                path: '/RecruitResumeManage',
                name: 'RecruitResumeManage',
                component: () => Promise.resolve(import('@/views/App/Site/Recruit/RecruitResume.vue')),
                meta: { title: '简历管理' }
              },
              {
                path: '/RecruitCreate',
                name: 'RecruitCreate',
                component: () => Promise.resolve(import('@/views/App/Site/Recruit/RecruitCreate.vue')),
                meta: { title: '编辑招聘' }
              }
            ]
          },
          {
            path: '/NewsLayou',
            name: 'NewsLayou',
            redirect: { name: 'NewsManage' }, // 跳转到下一级第一个
            component:()=>Promise.resolve(commonLayout),
            meta: { title: '信息管理' },
            children: [
              {
                path: '/NewsManage',
                name: 'NewsManage',
                component: () => Promise.resolve(import('@/views/App/Site/News/News/NewsIndex.vue')),
                meta: { title: '信息列表' }
              },
              {
                path: '/CreateNews',
                name: 'CreateNews',
                component: () => Promise.resolve(import('@/views/App/Site/News/News/NewsCreate.vue')),
                meta: { title: '编辑信息' }
              },
              {
                path: '/NewsImageManage',
                name: 'NewsImageManage',
                component: () => Promise.resolve(import('@/views/App/Site/News/Images/ImageIndex.vue')),
                meta: { title: '图文列表' }
              },
              {
                path: '/CreateNewsImages',
                name: 'CreateNewsImages',
                component: () => Promise.resolve(import('@/views/App/Site/News/Images/ImageCreate.vue')),
                meta: { title: '编辑图文' }
              },
              {
                path: '/NewsSingleManage',
                name: 'NewsSingleManage',
                component: () => Promise.resolve(import('@/views/App/Site/News/SinglePage/SingleIndex.vue')),
                meta: { title: '单页管理' }
              }
            ]
          },
        ]
      }
      // #endregion
      // #region 文件管理系统
      ,{
        path: '/ImageLibManage',
        name: 'ImageLibManage',
        redirect: { name: 'ImageLibIndex' }, // 跳转到下一级第一个
        component: ()=>Promise.resolve(commonLayout),
        meta: { title: '文件管理',keepAlive: true },
        children: [
          {
            path: '/ImageLibIndex',
            name: 'ImageLibIndex',
            meta: { title: '图片库',keepAlive: true },
            component: () => Promise.resolve(import('@/views/App/FileSystem/Images/ImageLibIndex.vue'))
          },
          {
            path: '/FileLibIndex',
            name: 'FileLibIndex',
            meta: { title: '文件库',keepAlive: true },
            component: () => Promise.resolve(import('@/views/App/FileSystem/Files/FileLibIndex.vue'))
          },
          {
            path: '/VideoLibIndex',
            name: 'VideoLibIndex',
            meta: { title: '视频库',keepAlive: true },
            component: () => Promise.resolve(import('@/views/App/FileSystem/Videos/VideoLibIndex.vue'))
          },
          {
            path: '/AudioLibIndex',
            name: 'AudioLibIndex',
            meta: { title: '音频库',keepAlive: true },
            component: () => Promise.resolve(import('@/views/App/FileSystem/Audios/AudioLibIndex.vue'))
          }
        ]
      }
      // #endregion
      // #region 商城配置
      ,{
        path: '/MallLayou',
        name: 'MallLayou',
        redirect: { name: 'MallManage' }, // 跳转到下一级第一个
        component: ()=>Promise.resolve(commonLayout),
        meta: { title: '商城配置',keepAlive: true },
        children: [
          {
            path: '/MallUnitManage',
            name: 'MallUnitManage',
            redirect: { name: 'MallUnitIndex' }, // 跳转到下一级第一个
            component:()=>Promise.resolve( commonLayout),
            meta: { title: '计量单位',keepAlive: true },
            children: [
              {
                path: '/MallUnitIndex',
                name: 'MallUnitIndex',
                meta: { title: '单位列表',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Unit/List/MallUnitIndex.vue'))
              },
              {
                path: '/MallUnitCreate',
                name: 'MallUnitCreate',
                meta: { title: '编辑单位',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Unit/List/MallUnitCreate.vue'))
              },
              {
                path: '/MallUnitRecyclebinIndex',
                name: 'MallUnitRecyclebinIndex',
                meta: { title: '回收站',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Unit/Recyclebin/MallUnitRecyclebinIndex.vue'))
              }
            ]
          },
          {
            path: '/MallBrandManage',
            name: 'MallBrandManage',
            redirect: { name: 'MallBrandIndex' }, // 跳转到下一级第一个
            component:()=>Promise.resolve( commonLayout),
            meta: { title: '品牌管理',keepAlive: true },
            children: [
              {
                path: '/MallBrandIndex',
                name: 'MallBrandIndex',
                meta: { title: '品牌列表',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Brand/List/MallBrandIndex.vue'))
              },
              {
                path: '/MallBrandCreate',
                name: 'MallBrandCreate',
                meta: { title: '编辑品牌',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Brand/List/MallBrandCreate.vue'))
              },
              {
                path: '/MallBrandSortIndex',
                name: 'MallBrandSortIndex',
                meta: { title: '排序管理',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Brand/Sort/MallBrandSortIndex.vue'))
              },
              {
                path: '/MallBrandRecyclebinIndex',
                name: 'MallBrandRecyclebinIndex',
                meta: { title: '回收站',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Brand/Retrieve/MallBrandRetrieveIndex.vue'))
              }
            ]
          },
          {
            path: '/MallAttrManage',
            name: 'MallAttrManage',
            redirect: { name: 'MallAttrIndex' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '属性管理',keepAlive: true },
            children: [
              {
                path: '/MallAttrIndex',
                name: 'MallAttrIndex',
                meta: { title: '属性列表',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Attr/List/MallAttrIndex.vue'))
              },
              {
                path: '/MallAttrCreate',
                name: 'MallAttrCreate',
                meta: { title: '编辑属性',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Attr/List/MallAttrCreate.vue'))
              },
              {
                path: '/MallAttrSortIndex',
                name: 'MallAttrSortIndex',
                meta: { title: '排序管理',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Attr/Sort/MallAttrSortIndex.vue'))
              },
              {
                path: '/MallAttrRecyclebinIndex',
                name: 'MallAttrRecyclebinIndex',
                meta: { title: '回收站',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Attr/Retrieve/MallAttrRetrieveIndex.vue'))
              }
            ]
          },
          {
            path: '/MallAttrGroupManage',
            name: 'MallAttrGroupManage',
            redirect: { name: 'MallAttrGroupIndex' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '属性组管理',keepAlive: true },
            children: [
              {
                path: '/MallAttrGroupIndex',
                name: 'MallAttrGroupIndex',
                meta: { title: '分组列表',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/AttrGroup/List/MallAttrGroupIndex.vue'))
              },
              {
                path: '/MallAttrGroupCreate',
                name: 'MallAttrGroupCreate',
                meta: { title: '编辑分组',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/AttrGroup/List/MallAttrGroupCreate.vue'))
              },
              {
                path: '/MallAttrGroupSortIndex',
                name: 'MallAttrGroupSortIndex',
                meta: { title: '排序管理',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/AttrGroup/Sort/MallAttrGroupSortIndex.vue'))
              },
              {
                path: '/MallAttrGroupRecyclebinIndex',
                name: 'MallAttrGroupRecyclebinIndex',
                meta: { title: '回收站',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/AttrGroup/Retrieve/MallAttrGroupRetrieveIndex.vue'))
              }
            ]
          },
          {
            path: '/MallSpecGroupManage',
            name: 'MallSpecGroupManage',
            redirect: { name: 'MallSpecGroupIndex' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '规格分组',keepAlive: true },
            children: [
              {
                path: '/MallSpecGroupIndex',
                name: 'MallSpecGroupIndex',
                meta: { title: '分组列表',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/SpecGroup/List/MallSpecGroupIndex.vue'))
              },
              {
                path: '/MallSpecGroupCreate',
                name: 'MallSpecGroupCreate',
                meta: { title: '编辑分组',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/SpecGroup/List/MallSpecGroupCreate.vue'))
              },
              {
                path: '/MallSpecGroupSortIndex',
                name: 'MallSpecGroupSortIndex',
                meta: { title: '排序管理',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/SpecGroup/Sort/MallSpecGroupSortIndex.vue'))
              },
              {
                path: '/MallSpecGroupRecyclebinIndex',
                name: 'MallSpecGroupRecyclebinIndex',
                meta: { title: '回收站',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/SpecGroup/Recyclebin/MallSpecGroupRecyclebinIndex.vue'))
              }
            ]
          },
          {
            path: '/MallSpecLayer',
            name: 'MallSpecLayer',
            redirect: { name: 'MallSpecIndex' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '产品规格',keepAlive: true },
            children: [
              {
                path: '/MallSpecIndex',
                name: 'MallSpecIndex',
                meta: { title: '规格列表',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Spec/List/MallSpecIndex.vue'))
              },
              {
                path: '/MallSpecCreate',
                name: 'MallSpecCreate',
                meta: { title: '编辑规格',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Spec/List/MallSpecCreate.vue'))
              },
              {
                path: '/MallSpecSortIndex',
                name: 'MallSpecSortIndex',
                meta: { title: '排序管理',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Spec/Sort/MallSpecSortIndex.vue'))
              },
              {
                path: '/MallSpecRecyclebinIndex',
                name: 'MallSpecRecyclebinIndex',
                meta: { title: '回收站',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Spec/Recyclebin/MallSpecRecyclebinIndex.vue'))
              }
            ]
          },
          {
            path: '/MallManage',
            name: 'MallManage',
            redirect: { name: 'MallCategoryIndex' }, // 跳转到下一级第一个
            component: ()=>Promise.resolve(commonLayout),
            meta: { title: '分类管理',keepAlive: true },
            children: [
              {
                path: '/MallCategoryIndex',
                name: 'MallCategoryIndex',
                meta: { title: '分类列表',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Category/List/MallCategoryIndex.vue'))
              },
              {
                path: '/MallCategoryCreate',
                name: 'MallCategoryCreate',
                meta: { title: '编辑分类',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Category/List/MallCategoryCreate.vue'))
              },
              {
                path: '/MallCategorySortIndex',
                name: 'MallCategorySortIndex',
                meta: { title: '排序管理',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Category/Sort/MallCategorySortIndex.vue'))
              },
              {
                path: '/MallCategorySortChildIndex',
                name: 'MallCategorySortChildIndex',
                meta: { title: '排序管理',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Category/Sort/MallCategorySortChildIndex.vue'))
              },
              {
                path: '/MallCategoryRecyclebinIndex',
                name: 'MallCategoryRecyclebinIndex',
                meta: { title: '回收站',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Category/Retrieve/MallCategoryRetrieveIndex.vue'))
              }
            ]
          }
        ]
      }
      // #endregion
      // #region 产品管理
      ,{
        path: '/MallGoodsLayou',
        name: 'MallGoodsLayou',
        redirect: { name: 'MallGoodsIndex' }, // 跳转到下一级第一个
        component: ()=>Promise.resolve(commonLayout),
        meta: { title: '产品管理',keepAlive: true },
        children: [
          {
            path: '/MallGoodsManage',
            name: 'MallGoodsManage',
            redirect: { name: 'MallGoodsIndex' }, // 跳转到下一级第一个
            component:()=>Promise.resolve( commonLayout),
            meta: { title: '所有产品',keepAlive: true },
            children: [
              {
                path: '/MallGoodsIndex',
                name: 'MallGoodsIndex',
                meta: { title: '产品列表',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Goods/List/MallGoodsIndex.vue'))
              },
              {
                path: '/MallGoodsCreate',
                name: 'MallGoodsCreate',
                meta: { title: '编辑产品',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Goods/List/MallGoodsCreate.vue'))
              },
              {
                path: '/MallGoodsRecyclebinIndex',
                name: 'MallGoodsRecyclebinIndex',
                meta: { title: '回收站',keepAlive: true },
                component: () => Promise.resolve(import('@/views/App/Mall/Goods/Recyclebin/MallGoodsRecyclebinIndex.vue'))
              }
            ]
          },
        ]
      }
      // #endregion
    ]
  },
  {
    path: '/login',
    name: 'login',
    component:()=>Promise.resolve( import('@/views/App/Account/LoginView.vue'))
  }
  
]


const router = createRouter({
  // 通过#号
  // history: createWebHashHistory(),
  // 取消地址中的#
  // 采用History模式，则需要在nginx中添加如下：
  //    add_header Cache-Control no-store;
	//		add_header Pragma no-cache;
	//		add_header Expires 0;
	//		采用history模式下需要配置nginx，不然刷新出现404 
	//		try_files $uri $uri/ /index.html;
	//		autoindex on;
  history: createWebHistory(),
  routes
})

export default router
