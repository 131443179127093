<template>
  <div class="ui-header-wrap">
   <!--Logo图标-->
    <div class="ui-logo"></div>
    <!-- 折叠按钮 -->
    <el-icon size="26" @click="collapseChage" class="collapseIcon">
        <Fold v-if="!collapse" />
        <Expand v-else />
    </el-icon>
    <!--显示面包屑菜单-->
    <breadcrumb-nav></breadcrumb-nav>
    <div class="ui-header-right">
      <!-- 全屏显示 -->
      <div class="btn-isFullScreen" @click="onFullScreen">
          <el-tooltip effect="dark" :content="isFullScreen?`取消全屏`:`全屏`" placement="bottom">
            <el-icon><Rank /></el-icon>
          </el-tooltip>
        </div>
        <!--消息提示-->
        <message-tip></message-tip>
         <!-- 用户信息 -->
        <user-info />
    </div>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import { ref, onBeforeMount, onMounted, getCurrentInstance } from 'vue'
import { Fold, Expand, Rank } from '@element-plus/icons-vue'
// import { useRoute } from 'vue-router'
import BreadcrumbNav from './BreadcrumbNav.vue'
import MessageTip from '@/Layout/components/MessageTip'
import UserInfo from '@/Layout/components/UserInfo'
export default {
  components: {
    Fold,
    Expand,
    Rank,
    BreadcrumbNav,
    MessageTip,
    UserInfo
  },
  setup(){
    // const route = useRoute()
    // 是否折叠
    const collapse = ref(false)
    const isFullScreen= ref(false)
    // 用于获取公共方法
    const { proxy } = getCurrentInstance()
    
    // 设置折叠事件方法
    const collapseChage=()=>{
      collapse.value = !collapse.value
      bus.$emit('collapse', collapse.value)
    }
    // 加载事件
    onBeforeMount(()=>{

    })
    onMounted(()=>{
      // isFullScreen.value=proxy.isFullScreen()
    })
    // 全屏事件
    const onFullScreen=()=>{
      if(isFullScreen.value){
        proxy.exitFullScreen()
      }else{
        proxy.handleFullScreen()
      }
      isFullScreen.value=!isFullScreen.value
    }
    return {
      collapse,
      collapseChage,
      isFullScreen,
      onFullScreen
    }
  }
}
</script>

<style scoped>

.ui-header-wrap{
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
}
.ui-header-right{
  flex: 1;
  margin-left: 20px;
  margin-right: 16px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.ui-logo{
    width: 150px;
    height: 50px;
    margin-left: 16px;
    background: url('../../assets/img/logo.png') left center no-repeat;
    background-size: 120px;
}
.collapseIcon {
    padding: 0 21px;
    cursor: pointer;
    line-height: 55px;
}
.btn-isFullScreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell,
.btn-isFullScreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
</style>