import { createStore } from 'vuex'
/**
 * 使用方法：
 * 1、store.commit('addTab', route) 直接操作mutations里的方法去修改state的值
 * 2、store.dispatch('addTab', route) 直接操作actions里的方法，actions里可以定义异步方法
 * 3、调用方：watch(()=>store.state.commandItem,async(val)=>{})
 */


export default createStore({
  // 存储属性信息
  state: {
    // 登录状态
    loginStatus:true,
    // 是否显示标签栏
    showTag: true,
    // 定义tabs标签页
    tabs:{
      // 当前标签页
      active:0,
      // 当前路径
      activePath:null,
      // List
      items:[]
    },
    // websocket接收的命令
    commandItem: {
      cmd: null,
      body: null,
    }
  },
  // 获取属性信息
  getters: {
    /**
     * 获取所有标签
     * @param {*} state 
     * @returns 
     */
    getTabs(state){
      return state.tabs
    },
    /**
     * 获取Websocket命令
     * @param {*} state 
     * @returns 
     */
    getCommandItem(state){
      return state.commandItem
    }
  },
  // 定义编辑state中的属性方法
  mutations: {
    /**
     * 设置登录状态
     * @param {*} state 
     * @param {*} status 
     */
    loginStatus(state,status){
      state.loginStatus=status
    },
    /**
     * 是否显示标签栏
     * @param {*} state 
     * @param {*} show true=显示，false=不显示
     */
    setShowTag(state,show){
      state.showTag=show
    },
    /**
     * 添加标签
     * @param {*} state State属性
     * @param {*} route  路由对象
     */
    addTab(state,route){
     
      state.tabs.items.push(route)
      // 去重复
      let nArr = state.tabs.items.filter((currentValue, currentIndex, selfArr)=>{
        return selfArr.findIndex(x =>x.path === currentValue.path) === currentIndex
      })
      state.tabs.items=[...nArr]
      var index=state.tabs.items.findIndex(x =>x.path === route.path)
      var getCurrentIndex=index<0?0:index
      state.tabs.active=getCurrentIndex
      state.tabs.activePath=state.tabs.items[getCurrentIndex].path
    },
    /**
     * 移出签标，不能移出主页
     * @param {*} state 
     * @param {*} route 路由对象
     */
    removeTab(state,route){
      let index=state.tabs.items.findIndex(x => x.title === route.title && x.path==route.path && !x.home)
      if(index>=0){
        state.tabs.items.splice(index,1)
      }
      
      if(state.tabs.active>state.tabs.items.length-1){
        state.tabs.active=state.tabs.items.length-1
        state.tabs.activePath=state.tabs.items[state.tabs.active].path
      }
    },
    /**
     * 移出所有
     * @param {*} state 
     */
    removeTabAll(state){
      state.tabs.items=[]
      state.tabs.active=0
      state.tabs.activePath='/dashboard'
      state.tabs.items.push({title:'大数据中心',path:'/dashboard',home:true})
    },
   /**
   * Tab标签排序
   * @param {*} state 
   */
    tabsSort(state){
      let nArr = state.tabs.items.filter((currentValue, currentIndex, selfArr)=>{
        return selfArr.findIndex(x =>x.path === currentValue.path) === currentIndex
      })
      state.tabs.items=[...nArr]
      let count=state.tabs.items.length

      if(count>0){
        // 检查大数据中心是否在第一位
        var ishome=state.tabs.items[0].home
        
        if(ishome==false){
        
          var findHome=state.tabs.items.find(x=>x.home==true)
          var getOtherTabs=state.tabs.items.filter(x=>x.home==false)

          
          if(findHome==null || findHome==undefined){
            state.tabs.items=[]
            if(findHome==null || findHome==undefined){
              state.tabs.items.push({title:'大数据中心',path:'/dashboard',home:true})
            }else{
              state.tabs.items.push(findHome)
            }
            state.tabs.items.push(...getOtherTabs)
          }
        }
      }
    },
    /**
     * 添加Websocket命令
     * @param {*} state 
     * @param {*} cmd js命令
     */
    addCommand(state,cmd){
      if(cmd){
        state.commandItem = cmd
      }
    }
  },
  // 数据的异步操作
  actions: {

  },
  // 处理数据的唯一途径，state的改变和赋值都在这里
  modules: {

  }
})
