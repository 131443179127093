
import CardForm from './components/GL-Card-Form.vue'
import TabMenu from './components/GL-Tab-Menu-Left.vue'
import ActiveBtn from './components/GL-Text-ActiveBtn.vue'

// ✨如果使用的是 JS 可以删除类型校验

const components= {
    CardForm,
    TabMenu,
    ActiveBtn
}

export default components