<template>
  <div class="ui-layer-content">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
   </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.ui-layer-content{
    width: auto;
    flex: 1;
    background: #fdfdfd;
    overflow: hidden !important;
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        width: 0.5em;
    }
    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 16px;
    }
  }
  /* 左侧滑动进出 */
	.leftFand-enter-active,.leftFand-leave-active {
		animation: leftFand-in .5s linear 1 forwards;
	}
	.leftFand-enter,.leftFand-leave-to {
    animation: leftFand-in .5s reverse;
  }
  @keyframes leftFand-in {
  0% {
    transform: translate3D(0, 0, 0) scale(0);
    opacity: 1.0;
  }
  50% {
    transform: translate3D(-70%, 0, 0) scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: translate3D(-50%, 0, 0) scale(1);
    opacity: 0.0;
  }
}
  /* 上下滑动进出 */
  .topFand-enter-active{
    transition: all .2s linear;
    transform: translate3D(0, 0, 0);
  }
  /**上下滑动 */
  .topFand-leave-active {
      transition: all .2s linear;
      transform: translate3D(0, 0, 0);
	}
  .topFand-enter{
    transform: translate3D(0,-50%,0);
  }
  .topFand-leave-to {
		transform: translate3D(0,-50%,0);
	}

/**放大缩小--有冲击感 */
.zoom-enter-active {
  animation: zoom-in .5s;
}
.zoom-leave-active {
  animation: zoom-in .5s reverse;
}
@keyframes zoom-in {
  0% {
    transform: scale(0);
    opacity: 1.0;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.0;
  }
}
/**转动 */
.rotate-enter-active {
  animation: rotate-in .5s;
}
.rotate-leave-active {
  animation: rotate-in .5s reverse;
}
@keyframes rotate-in {
  from {
    transform: rotate(0deg) scale(0);
    opacity: 1.0;
  }
  to {
    transform: rotate((360deg)) scale(1.5);
    opacity: 0.1;
  }
}
</style>
