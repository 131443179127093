import { httpPost, httpGet } from '@/http/request'
// 支付接口配置
var prefix='/api/System/PermissionManager/'
/**
 * 管理员获取自己的操作权限列表【增、删、改、查】
 * @param {*} path 路径
 * @returns 返回单条对应文件名的【增、删、改、查】权限
 */
export const getPermission = (path) => httpPost(prefix + 'QueryByAction', { routerName: path.replace("/","") })
/**
 * 根据管理员权限获取相应的后台左边的功能菜单
 * @returns 返回拥有的菜单列表
 */
export const queryByPermissionsMenu = () => httpGet(prefix + 'QueryByPermissionsMenu')
/**
 * 获取树型菜单--主要用于添加或修改角色时使用
 * @returns 返回所有后台菜单
 */
export const getTreeMenu = () => httpGet(prefix + 'QueryByTreeMenu')
