<template>
  <div class="tab-wrap">
    <ul class="tab-box">
      <li class="tab-item" v-for="(item,index) in items" :key="index" :class="{'tab-item-active':index==active}" @click="itemClick(index)">
          {{ item }}
      </li>
    </ul>
    <div class="tab-bottom"></div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
export default {
    props:{
        modelValue:{
            type:Number,
            default:0
        },
        items:{
            type:Array,
            default:()=>null
        }
    },
    setup(props,context){
      const active=ref(0)
      const itemClick=(index)=>{
        active.value=index
        context.emit('update:modelValue',index)
      }
      watch(()=>props.modelValue,(v)=>{
        if(v!=null){
          active.value=v
        }
      },{deep:true,immediate:true})
      return {
        active,
        itemClick
      }
    }
}
</script>

<style lang="scss" scoped>
 .tab-wrap{
   margin: 0px;
   padding: 0px;
   height: 100%;
   display: flex;
   flex-direction: column;
   overflow-x: hidden;
   overflow-y: auto;
   .tab-box{
    .tab-item{
        height: 54px;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-right: 1px solid #e8e8e8;
        border-left: 5px solid transparent;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        transition: all 800ms;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
      }
      .tab-item-active{
        border-left: 5px solid rgb(255, 102, 0);
        border-right: 1px solid #fff;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        padding-left: 0px;
        color: #ff6600;
        background: #fff;
      }
    }
    .tab-bottom{
      flex: 1;
      border-right: 1px solid #e8e8e8;
    }
 }
</style>