
import { httpGet, httpPost, httpDelete } from '@/http/request'
var prefix='/api/System/Authorization/'
var prefixAdmin='/api/System/Admin/'
/**
 * 系统管理员登录接口
 * @param {*} param 
 * @returns 
 */
export const login = (param) => httpPost(prefix+'Login', param)
/**
 * 刷新Token
 * @param {*} param 
 * @returns 
 */
export const refreshToken = (param) => httpPost(prefix+'RefreshToken', param)
/**
 * 分页
 * @param {*} arg 
 * @returns 
 */
export const getPager = (arg) => httpGet(prefixAdmin+'Page', arg)
/**
 * 批量删除管理员
 * @param {*} params 
 * @returns 
 */
export const deletes = (params) => httpDelete(prefixAdmin+'Deletes', params)
/**
 * 添加管理员
 * @param {*} params 
 * @returns 
 */
export const add = (params) => httpPost(prefixAdmin+'Create', params)
/**
 * 更新管理员信息
 * @param {*} params 
 * @returns 
 */
export const update = (params) => httpPost(prefixAdmin+'Update', params)
/**
 * 重置密码
 * @param {*} params 
 * @returns 
 */
export const reSetPassword = (params) => httpPost(prefixAdmin+'ReSetPassword', params)
/**
 * 登录管理员修改密码
 * @param {*} params 
 * @returns 
 */
export const updatePassword = (params) => httpPost(prefixAdmin+'UpdatePassword', params)
/**
 * 设置管理员状态
 * @param {*} params 
 * @returns 
 */
export const updateStatus = (params) => httpPost(prefixAdmin+'UpdateStatus', params)
/**
 * 获取管理员基本信息
 * @param {*} id 
 * @returns 
 */
export const get = (id) => httpGet(prefixAdmin+'QueryById', { id })
/**
 * 获取登录管理员的基本信息
 * @returns 返回Json
 */
export const queryByBaseInfo = () => httpGet(prefixAdmin+'QueryByBaseInfo')
/**
 * 导出模板
 * @returns 返回Json
 */
export const exportTemplate = () => httpGet(prefixAdmin+'ExportTemplate')
/**
 * 导入模板生成账号
 * @returns 返回Json
 */
export const importTemplate = (path) => httpPost(prefixAdmin+'ImportTemplate',{path})
